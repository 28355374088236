import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { AuthState, initSentry, initTagManager, updateFavicon } from '@clatter/platform';
import { Loader } from '@clatter/ui';
import App from './app/app';
import { store } from './app/store';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE_KEY);

updateFavicon();
initTagManager();
initSentry();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistStore(store)}>
      <StrictMode>
        <BrowserRouter>
          <AuthState>
            <App />
          </AuthState>
        </BrowserRouter>
      </StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
