import React, { useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  DndContext,
  rectIntersection,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import SortTabSlidePicker from './SortTabSlidePicker';
import SortTabSlidesHierarchy from './SortTabSlidesHierarchy';
import { StickyContainer } from '@clatter/ui';
import { difference } from 'lodash';
import { useDeepCompareMemo } from '@clatter/platform';

const SortTabSelector = ({
  tabs,
  slides,
  hashedSlidesIds,
  coverSlideId,
  activeItems,
  isSlideActive,
  newSlidesIds,
  onClearActiveItems,
  onPreview,
  onRemoveSlide,
  onAdd,
  onSelect,
  onChange,
  zoomValue,
  activeView,
  contentSlidesGroups,
  hideableDividersPicker = false,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // activation constraint is needed to enable click events from slide item
      },
    }),
  );
  const [overId, setOverId] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const handleDragStart = (event) => {
    setActiveId(event.active?.id);
  };

  const activeSlideIndex = useMemo(
    () => slides.findIndex((slide) => slide.hashedId === activeId),
    [slides, activeId],
  );

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (active?.id === coverSlideId) {
      return;
    }

    // check if hovering over group item and set overId accordingly
    if (active?.id !== over?.id) {
      if (over?.data?.current?.restricted) {
        const groupId = over.data.current.groupId;
        const groupStartIndex = slides.findIndex(
          (slide) => slide.groupId === groupId,
        );
        setOverId(slides[groupStartIndex - 1]?.hashedId);
      } else {
        setOverId(over?.id);
      }
    }
  };

  const resetSlideDragState = () => {
    setOverId(null);
    setActiveId(null);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active.data.current.disabled) {
      return;
    }

    if (overId) {
      resetSlideDragState();
    }

    let isDraggingMultipleSlides = false;
    let slidesToMoveHashedIds = [];

    // handle move group
    if (active?.data?.current?.groupId) {
      isDraggingMultipleSlides = true;
      for (const slideId of contentSlidesGroups[
        active?.data?.current?.groupId
      ]) {
        slidesToMoveHashedIds.push(
          hashedSlidesIds.find((hashed) => hashed.includes(slideId)),
        );
      }
    }

    // handle move multiple selected
    if (activeItems.some((id) => id === active.id)) {
      isDraggingMultipleSlides = true;
      slidesToMoveHashedIds = [...activeItems];
    }

    // adding item to the presentation
    if (
      active.id !== overId &&
      !over.data.current.droppable &&
      active.data.current.droppable
    ) {
      // insert after hovered element
      const insertAfterIndex = hashedSlidesIds.indexOf(overId) + 1;
      onAdd({ index: insertAfterIndex, slideId: active.id });
      return;
    }

    // sorting items
    if (active.id !== overId) {
      const slidesWithoutActiveItems = difference(
        hashedSlidesIds,
        isDraggingMultipleSlides ? slidesToMoveHashedIds : [active.id],
      );
      const newIndex =
        slidesWithoutActiveItems.indexOf(overId) ??
        slidesWithoutActiveItems.length;

      const oldIndex = active?.data?.current?.sortable.index;

      onChange({
        insertAt: newIndex,
        oldIndex: oldIndex,
        isDraggingMultipleSlides: isDraggingMultipleSlides,
        slidesToMoveHashedIds: slidesToMoveHashedIds,
      });
    }

    // clear multi selection
    onClearActiveItems();
  };

  const hideDividers = useDeepCompareMemo(() => {
    if (hideableDividersPicker && !tabs.some((tab) => tab?.items?.length > 0)) {
      return true;
    }
    return false;
  }, [tabs, hideableDividersPicker]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={4} lg={3}>
          <StickyContainer>
            <SortTabSlidePicker tabs={tabs} />
          </StickyContainer>
        </Grid>

        <SortableContext items={hashedSlidesIds}>
          <Grid item xs={12} md={8} lg={9}>
            <SortTabSlidesHierarchy
              slides={slides}
              activeView={activeView}
              isSlideActive={isSlideActive}
              newSlidesIds={newSlidesIds}
              onRemove={onRemoveSlide}
              overId={overId}
              activeId={activeId}
              activeSlideIndex={activeSlideIndex}
              onSelect={onSelect}
              onPreview={onPreview}
              zoomValue={zoomValue}
              contentSlidesGroups={contentSlidesGroups}
            />
          </Grid>
        </SortableContext>
      </Grid>
    </DndContext>
  );
};

export default SortTabSelector;
