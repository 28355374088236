import React, { useMemo } from 'react';
import styled from 'styled-components';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { useSortable } from '@dnd-kit/sortable';
import classNames from 'classnames';
import { CSS } from '@dnd-kit/utilities';
import { getSlideGroupClassName } from '@clatter/platform';
import { getRestrictedState } from './SortTabSlidesHierarchy';

const StyledSlideSortThumbnail = styled.div`
  position: relative;
  width: ${({ width }) => width ?? '100%'};
  background-color: ${({ theme }) => theme.slideSortThumbnail.backgroundColor};
  transition: all 0.2s ease-in-out;
  min-height: 128px;

  &.dragging {
    z-index: 5;
    opacity: ${({ theme }) => theme.slideDividerThumbnail.draggingOpacity};
  }

  &:focus {
    outline: none !important;
  }

  &.active {
    &:after {
      z-index: 2;
      pointer-events: none;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: none;
      border: ${({ theme }) => theme.slideSortThumbnail.activeBorder};
    }
  }

  &.slide-over {
    &:after {
      position: absolute;
      content: '';
      width: ${({ theme }) => theme.slideSortThumbnail.slideOverBarWidth};
      height: 100%;
      background-color: ${({ theme }) =>
        theme.slideSortThumbnail.slideOverBarColor};
      right: -10px;
      top: 0;
    }
  }

  &:hover {
    > div:first-child {
      transform: scale(0.93);
      transition: all 0.3s ease-in-out;
    }

    transition: all 0.3s ease-in-out;
    box-shadow: ${({ theme }) => theme.slideSortThumbnail.hoverShadow};
  }

  &.highlighted {
    background-color: ${({ theme }) =>
      theme.slideSortThumbnail.addedBackgroundColor};

    img {
      opacity: 0.6;
    }
  }

  .thumbnail-container {
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .thumbnail {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .footer {
    width: 100%;
    padding: 0 8px 5px 8px;
  }

  .title {
    cursor: pointer;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${({ theme }) => theme.slideSortThumbnail.fontSize};
    color: ${({ theme }) => theme.slideSortThumbnail.color};
    margin-bottom: 0;
  }

  &.disabled {
    img {
      cursor: not-allowed;
    }

    > div:first-child {
      transform: none;
    }
    box-shadow: none;
  }

  &.group-start,
  &.group-middle,
  &.group-end {
    &:before {
      box-sizing: content-box;
      z-index: -1;
      position: absolute;
      pointer-events: none;
      content: '';
      height: 108%;
      width: calc(100% + 10px);
      background-color: ${({ theme }) => theme.groups.sortGroupBackgroundColor};
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &.group-middle {
    &:before {
      border-right: 7px solid
        ${({ theme }) => theme.groups.sortGroupBackgroundColor};
      border-left: 7px solid
        ${({ theme }) => theme.groups.sortGroupBackgroundColor};
    }
  }
  &.group-start {
    &:before {
      border-width: 4px;
      border-left: ${({ theme }) => theme.groups.sortGroupBorder};
      border-right: 5px solid
        ${({ theme }) => theme.groups.sortGroupBackgroundColor};
    }
  }
  &.group-end {
    &:before {
      padding-right: 5px;
      border-right: ${({ theme }) => theme.groups.sortGroupBorder};
      border-left: 5px solid
        ${({ theme }) => theme.groups.sortGroupBackgroundColor};
    }
  }
`;

const StyledDeleteButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  z-index: 1;
  background-color: ${({ theme }) =>
    theme.slideSortThumbnail.deleteButton.backgroundColor};
  svg {
    color: ${({ theme }) => theme.slideSortThumbnail.deleteButton.color};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.slideSortThumbnail.deleteButton.hoverColor};
    }
  }

  &:disabled {
    pointer-events: none;
    svg {
      color: ${({ theme }) =>
        theme.slideSortThumbnail.deleteButton.disabledColor};
    }
  }
`;

const getThumbnailWidth = (zoomValue, gap) => {
  switch (zoomValue) {
    case 0:
      return `calc(100% / 9 - ${(gap * 8) / 9}px)`;
    case 20:
      return `calc(100% / 5 - ${(gap * 4) / 5}px)`;
    case 40:
      return `calc(100% / 4 - ${(gap * 3) / 4}px)`;
    case 60:
      return `calc(100% / 3 - ${(gap * 2) / 3}px)`;
    case 80:
      return `calc(100% / 2 - ${gap / 2}px)`;
    default:
      return '100%';
  }
};

const SlideSortThumbnail = ({
  id,
  index,
  item,
  isActive,
  slideOver,
  disableRemove,
  onPreview,
  onSelect,
  onRemove,
  isNew,
  zoomValue,
  disabled,
  contentSlidesGroups,
}) => {
  if (!item) {
    return null;
  }

  const restricted = useMemo(
    () =>
      getRestrictedState({
        slideId: item?.id,
        groupId: item?.groupId,
        contentSlidesGroups: contentSlidesGroups,
      }),
    [item?.id, item?.groupId, Object.keys(contentSlidesGroups)?.length],
  );

  const { isDragging, attributes, listeners, setNodeRef, transform } =
    useSortable({
      id: id,
      data: {
        disabled: !!disabled,
        restricted: restricted,
        groupId: item.groupId,
      },
    });

  const getTransform = () => {
    if (item.isDisabled) {
      return;
    }
    if (isDragging) {
      return CSS.Transform.toString(transform);
    }
  };

  const style = {
    transform: getTransform(),
    transition: 'width .3s ease',
  };

  // get class names for group display
  const isInGroup = useMemo(() => {
    return !!contentSlidesGroups[item.groupId];
  }, [item.id, Object.keys(contentSlidesGroups)?.length]);

  const slideClassName = useMemo(() => {
    if (isInGroup) {
      return getSlideGroupClassName({
        slideGroup: contentSlidesGroups[item.groupId],
        slideId: item.id,
      });
    }
    return '';
  }, [item.id, isInGroup, Object.keys(contentSlidesGroups)?.length]);

  const handleRemoveClick = () => {
    onRemove({ index: index });
  };

  const handleSelect = (event) => {
    if (disabled) {
      return;
    }
    onSelect({ event, item });
  };

  return (
    <StyledSlideSortThumbnail
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames(slideClassName, {
        highlighted: isNew,
        active: isActive,
        dragging: isDragging,
        disabled: disabled,
        'slide-over': slideOver && !isDragging,
      })}
      width={getThumbnailWidth(zoomValue, 20)}
    >
      <div className="thumbnail-container">
        {!item.isRequired && (
          <StyledDeleteButton
            type="button"
            onClick={handleRemoveClick}
            disabled={disableRemove}
          >
            <DeleteOutlinedIcon />
          </StyledDeleteButton>
        )}

        <img
          src={item.imageUrl}
          alt={item.title}
          onClick={handleSelect}
          className="thumbnail"
        />
      </div>

      <div className="footer">
        <h5 className="title" title={item.title} onClick={onPreview}>
          {item.title}
        </h5>
      </div>
    </StyledSlideSortThumbnail>
  );
};

export default SlideSortThumbnail;
