import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, Tabs } from '@clatter/ui';
import SlideDividerThumbnail from './SlideDividerThumbnail';

const StyledTabContainer = styled.div`
  > div {
    min-height: ${({theme}) => theme.sortTabSideTabsContent.height};
    padding: 20px 0;
  }
`;

const StyledTabContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: ${({ theme }) => theme.sortTabSideTabsContent.rowGap};
  padding: ${({ theme }) => theme.sortTabSideTabsContent.padding};
`;

const RenderTabContent = ({ items }) => {
  if (!items && !Array.isArray(items)) {
    return null;
  }

  return (
    <StyledTabContainer>
      <Card>
        <StyledTabContent>
          {items.map((item) => (
            <SlideDividerThumbnail key={item.id} id={item.id} item={item} />
          ))}
        </StyledTabContent>
      </Card>
    </StyledTabContainer>
  );
};

const SortTabSlidePicker = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  // @todo: refactor Tabs component to use id of a tab item instead of object
  // this has to be done also in SL tool
  return (
    <Tabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab}>
      {tabs.map((tab) => (
        <RenderTabContent key={tab.label} items={tab.items} />
      ))}
    </Tabs>
  );
};

export default SortTabSlidePicker;
