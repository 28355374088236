import React from 'react';
import styled from 'styled-components';

const StyledStickyContainer = styled.div`
  position: sticky;
  left: ${({ left }) => left ?? 0};
  top: ${({ top }) => top ?? '30px'};
  z-index: ${({ zindex }) => zindex ?? 3};
`;

const StickyContainer = ({ top, left, zindex, children }) => {
  return (
    <StyledStickyContainer top={top} left={left} zindex={zindex}>
      {children}
    </StyledStickyContainer>
  );
};

export default StickyContainer;
