import React, { useMemo } from 'react';
import {
  DragIndicator as DragIndicatorIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';
import classNames from 'classnames';
import IconButton from './IconButton';
import { getSlideGroupClassName } from '@clatter/platform';
import { getRestrictedState } from './SortTabSlidesHierarchy';

const StyledSlideListItem = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 7fr;
  break-inside: avoid-column;
  padding: ${({ theme }) => theme.slideListItem.containerPadding};
  background-color: ${({ theme }) =>
    theme.slideListItem.containerBackgroundColor};
  height: 65px;

  .index-number {
    margin: 0;
    font-weight: bold;
    font-size: ${({ theme }) => theme.slideListItem.indexFontSize};
  }

  &.dragging {
    z-index: 15;
    opacity: ${({ theme }) => theme.slideListItem.draggingOpacity};
    background-color: white;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.slideListItem.padding};
  background-color: ${({ theme }) => theme.slideListItem.backgroundColor};

  &.slide-over {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: ${({ theme }) => theme.slideListItem.slideOverBarWidth};
      background-color: ${({ theme }) => theme.slideListItem.slideOverBarColor};
    }
  }

  &.highlighted {
    background: ${({ theme }) => theme.slideListItem.addedBackgroundColor};
  }

  &.active {
    &:after {
      z-index: 2;
      pointer-events: none;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: none;
      border: ${({ theme }) => theme.slideListItem.activeBorder};
    }
  }

  .drag-container {
    position: relative;
    cursor: move;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;

    &.disabled {
      cursor: not-allowed;
    }

    &:focus {
      outline: none !important;
    }
  }

  .actions-container {
    z-index: 1;
    display: flex;
    button {
      padding: 2px;
    }
  }

  .drag-icon {
    opacity: 0.3;
  }

  .title {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.slideListItem.fontWeight};
    font-size: ${({ theme }) => theme.slideListItem.fontSize};
  }
`;

const StyledGroupContainer = styled.div`
  position: relative;
  &.group-start,
  &.group-middle,
  &.group-end {
    &:before {
      position: absolute;
      pointer-events: none;
      content: '';
      height: 125%;
      width: 105%;
      background-color: ${({ theme }) => theme.groups.sortGroupBackgroundColor};
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &.group-middle {
    &:before {
      padding: 10px 0;
    }
  }
  &.group-start,
  &.group-end {
    &:after {
      position: absolute;
      pointer-events: none;
      content: '';
      height: 5px;
      width: 105%;
      background-color: ${({ theme }) => theme.groups.sortGroupBackgroundColor};
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      right: 0;
      margin: auto;
      bottom: 0px;
    }
  }
  &.group-start {
    &:before {
      border-top: ${({ theme }) => theme.groups.sortListGroupBorder};
    }
    &:after {
      bottom: -10px;
    }
  }
  &.group-end {
    &:before {
      border-bottom: ${({ theme }) => theme.groups.sortListGroupBorder};
    }
    &:after {
      top: -60px;
    }
  }
`;

const SlideListItem = ({
  index,
  id,
  item,
  isNew,
  counter,
  disabled,
  isActive,
  slideOver,
  onRemove,
  onPreview,
  onSelect,
  contentSlidesGroups,
}) => {
  if (!item) {
    return null;
  }

  const restricted = useMemo(
    () =>
      getRestrictedState({
        slideId: item?.id,
        groupId: item?.groupId,
        contentSlidesGroups: contentSlidesGroups,
      }),
    [item?.id, item?.groupId, Object.keys(contentSlidesGroups)?.length],
  );

  const { isDragging, attributes, listeners, setNodeRef, transform } =
    useSortable({
      id: id,
      data: {
        disabled: !!disabled,
        restricted: restricted,
        groupId: item.groupId,
      },
    });

  const style = {
    transform: isDragging && CSS.Transform.toString(transform),
    transition: 'none',
  };

  // get class names for group display
  const isInGroup = useMemo(() => {
    return !!contentSlidesGroups[item.groupId];
  }, [item.id, Object.keys(contentSlidesGroups)?.length]);

  const slideClassName = useMemo(() => {
    if (isInGroup) {
      return getSlideGroupClassName({
        slideGroup: contentSlidesGroups[item.groupId],
        slideId: item.id,
      });
    }
    return '';
  }, [item.id, isInGroup, Object.keys(contentSlidesGroups)?.length]);

  const handleRemoveClick = () => {
    onRemove({ index: index });
  };

  const handleSelect = (event) => {
    if (disabled) {
      return;
    }
    onSelect({ event, item });
  };

  return (
    <StyledSlideListItem
      ref={setNodeRef}
      style={style}
      className={classNames({
        dragging: isDragging,
      })}
    >
      <p className="index-number">{counter}.</p>

      <StyledGroupContainer className={classNames(slideClassName)}>
        <StyledContainer
          className={classNames({
            highlighted: isNew,
            active: isActive,
            'slide-over': slideOver && !isDragging,
          })}
        >
          <div
            className={classNames('drag-container', { disabled: disabled })}
            {...attributes}
            {...listeners}
            onClick={handleSelect}
          >
            <DragIndicatorIcon
              className={classNames('drag-icon', { hidden: disabled })}
            />
            <h5 className="title" title={item.title}>
              {item.title}
            </h5>
          </div>
          <div className="actions-container">
            <IconButton type="button" title="Preview" onClick={onPreview}>
              <VisibilityOutlinedIcon />
            </IconButton>

            <IconButton
              type="button"
              title="Remove"
              disabled={item.isRequired}
              onClick={handleRemoveClick}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </div>
        </StyledContainer>
      </StyledGroupContainer>
    </StyledSlideListItem>
  );
};

export default SlideListItem;
