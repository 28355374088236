import { getAxiosApiV2WithAuthorization } from '@clatter/platform';

const slidesApi = {
  getPresentationsWithSlideId: ({ slideId }) => {
    return getAxiosApiV2WithAuthorization({
      url: `/slides/${slideId}/presentations`,
      method: 'GET',
    });
  },
};

export default slidesApi;
