import {
  createEntityAdapter,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';

export const SORT_TAB_FEATURE_KEY = 'sortTab';
export const sortTabAdapter = createEntityAdapter();

export const setAddedSlidesIds = createAction('SET_ADDED_SLIDES_IDS');
export const clearAddedSlidesIds = createAction('CLEAR_ADDED_SLIDES_IDS');

export const initialSortTab = sortTabAdapter.getInitialState({
  addedSlidesIds: [],
});

export const sortTabSlice = createSlice({
  name: SORT_TAB_FEATURE_KEY,
  initialState: initialSortTab,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAddedSlidesIds, (state, { payload }) => {
        state.addedSlidesIds = payload;
      })
      .addCase(clearAddedSlidesIds, (state) => {
        state.addedSlidesIds = [];
      });
  },
});

export const sortTabReducer = sortTabSlice.reducer;

export const sortTabActions = sortTabSlice.actions;
