import { useState, useEffect } from 'react';
import SelectorCard from '../SelectorCard/SelectorCard';
import { getSlidesIdsBySlideOrder } from '@clatter/platform';

const StarterDeckSelector = ({
  starterDecks,
  selectedDeck,
  selectedSlidesIds = [],
  requiredSlidesIds,
  onChange,
}) => {
  if (!starterDecks) {
    return null;
  }

  const options = [
    {
      label: 'None',
      value: 'none',
    },
    ...starterDecks.map((deckItem) => ({
      label: deckItem.name,
      value: deckItem.id,
    })),
  ];

  const [selectedValue, setSelectedValue] = useState(() =>
    selectedDeck
      ? {
          label: selectedDeck?.name,
          value: selectedDeck?.id,
        }
      : options[0],
  );

  const handleUpdateState = (selectedItem) => {
    setSelectedValue(selectedItem);

    const deckData =
      selectedItem.value === 'none'
        ? null
        : starterDecks.find((deckItem) => deckItem?.id === selectedItem?.value);

    const slides = getSlidesIdsBySlideOrder({
      requiredSlidesIds: requiredSlidesIds,
      slides: deckData?.deckSlides || [],
    });

    onChange({ ...deckData, slides_ids: slides });
  };

  const handleDeckChange = (selectedItem) => {
    if (selectedItem?.value === selectedValue.value) {
      return;
    }

    // if user hasn't any slides selected
    // don't show warning message
    const userHasNotSelectedAnySlides =
      selectedSlidesIds?.filter(
        (slideId) => !requiredSlidesIds?.includes(slideId),
      )?.length === 0;

    if (userHasNotSelectedAnySlides) {
      handleUpdateState(selectedItem);
      return;
    }

    const message =
      selectedItem?.value === 'none'
        ? 'Selecting “None” will remove any slides you currently have selected in your library. Are you sure you want to do this?'
        : 'Selecting this deck will remove any slides you currently have selected in your library. Are you sure you want to do this?';

    if (window.confirm(message)) {
      handleUpdateState(selectedItem);
    }
  };

  // reset selector state
  useEffect(() => {
    if (!selectedDeck && selectedValue?.value !== options[0]?.value) {
      setSelectedValue(options[0]);
    }
  }, [selectedDeck, selectedValue?.value]);

  return (
    <SelectorCard
      testId="deck-assembly-selector-card"
      label="Select Starter Deck"
      onChange={handleDeckChange}
      options={options}
      value={selectedValue}
    />
  );
};

export default StarterDeckSelector;
