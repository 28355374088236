import { Card, CardContent } from '../Card';
import { SelectControl } from '../Forms';
import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectorCard = ({
  testId = '',
  padding = '20px 30px 30px',
  label = 'Select Option',
  onChange,
  options,
  value,
  ...props
}) => {
  return (
    <Card data-test-id={testId}>
      <CardContent padding={padding}>
        <StyledHeader>
          <h3 style={{ marginBottom: 0 }}>{label}</h3>
        </StyledHeader>

        <SelectControl
          onChange={onChange}
          menuPosition="absolute"
          options={options}
          value={value}
          {...props}
        />
      </CardContent>
    </Card>
  );
};

export default SelectorCard;
