import React from 'react';
import {
  DragIndicator as DragIndicatorIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';
import styled from 'styled-components';
import classNames from 'classnames';
import IconButton from './IconButton';

const StyledSlideListItemOverlay = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 7fr;
  break-inside: avoid-column;
  padding: ${({ theme }) => theme.slideListItem.containerPadding};
  background-color: ${({ theme }) =>
    theme.slideListItem.containerBackgroundColor};
  height: 65px;
  opacity: ${({ theme }) => theme.slideListItem.draggingOpacity};

  .index-number {
    margin: 0;
    font-weight: bold;
    font-size: ${({ theme }) => theme.slideListItem.indexFontSize};
  }
`;

const StyledContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.slideListItem.padding};
  background-color: ${({ theme }) => theme.slideListItem.backgroundColor};

  .drag-container {
    position: relative;
    cursor: move;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;

    &.disabled {
      cursor: not-allowed;
    }

    &:focus {
      outline: none !important;
    }
  }

  .actions-container {
    z-index: 1;
    display: flex;
    button {
      padding: 2px;
    }
  }

  .drag-icon {
    opacity: 0.3;
  }

  .title {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
    font-weight: ${({ theme }) => theme.slideListItem.fontWeight};
    font-size: ${({ theme }) => theme.slideListItem.fontSize};
  }
`;


const SlideListItemOverlay = ({
  item,
  counter,
  onPreview,
}) => {
  if (!item) {
    return null;
  }
  return (
    <StyledSlideListItemOverlay>
      <p className="index-number">{counter}.</p>
      <StyledContainer>
        <div className={classNames('drag-container')}>
          <DragIndicatorIcon className={classNames('drag-icon')}/>
          <h5 className="title" title={item.title}>
            {item.title}
          </h5>
        </div>
        <div className="actions-container">
          <IconButton type="button" title="Preview" onClick={onPreview}>
            <VisibilityOutlinedIcon />
          </IconButton>

          <IconButton
            type="button"
            title="Remove"
            disabled={item.isRequired}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </div>
      </StyledContainer>
    </StyledSlideListItemOverlay>
  );
};

export default SlideListItemOverlay;
