import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  FormControl,
  Divider,
  FileUpload,
  ImagePreview,
} from '@clatter/ui';

const StyledLogoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  row-gap: 5px;
  column-gap: 5px;

  .image-preview {
    width: 32.55%;
  }
`;

const CoBrandSelector = ({
  onCustomerLogoChange,
  onLogoSaveToProfile,
  onProfileLogoDelete,
  onUserLogoDelete,
  onProfileLogoTitleChange,
  handleClickProfileLogo,
  userLogo,
  userProfileLogos,
  formFieldName,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Customer Logo</Typography>
        <FormControl error={errors.customer_logo}>
          <Controller
            name={formFieldName}
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value } }) => {
              if (userLogo?.url) {
                return (
                  <Grid container spacing={3} alignItems="end">
                    <Grid item xs={5}>
                      <ImagePreview
                        imageData={userLogo}
                        handleDelete={() => {
                          onUserLogoDelete(userLogo);
                          onChange(null);
                        }}
                      />
                    </Grid>
                    {!!onLogoSaveToProfile && (
                      <Grid item xs>
                        <Checkbox
                          onChange={onLogoSaveToProfile}
                          text="Save this logo to my profile"
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              }

              return (
                <FileUpload
                  acceptedFileTypes=".jpg, .jpeg, .png"
                  onChange={(newFile) => {
                    onChange(newFile);
                    return onCustomerLogoChange(newFile);
                  }}
                  value={value || null}
                  previewOnTop
                  renderCustomPreview={() => null}
                />
              );
            }}
          />
        </FormControl>
        <Divider />
        {userProfileLogos.length === 0 ? (
          <Typography variant="body2">
            No logos saved in this profile
          </Typography>
        ) : (
          <StyledLogoList>
            {userProfileLogos.map((logo) => (
              <ImagePreview
                showBorder={false}
                justifyContent="center"
                key={logo._id}
                imageData={logo}
                handleDelete={() => onProfileLogoDelete(logo)}
                handleTitleChange={(newTitle) =>
                  onProfileLogoTitleChange(logo._id, newTitle)
                }
                handleClick={handleClickProfileLogo}
                // handleEditClick={() => console.log('xxx')}
              />
            ))}
          </StyledLogoList>
        )}
      </CardContent>
    </Card>
  );
};

export default CoBrandSelector;
