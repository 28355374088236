import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { differenceInSeconds, format } from 'date-fns';
import { requestStatus } from '@clatter/platform';
import ingestJobsApi from "../api/ingestJobs.api";
export const INGEST_JOBS_FEATURE_KEY = 'users';
export const ingestJobsAdapter = createEntityAdapter();

const formatDuration = (durationInSeconds) => format(new Date(0, 0, 0, 0, 0, durationInSeconds), 'HH:mm:ss');

const mapApiToStore = (item) => {
  const createdAt = new Date(item?.createdAt);
  const updatedAt = new Date(item?.updatedAt);
  const durationInSeconds = differenceInSeconds(updatedAt, createdAt);

  return {
    ...item,
    id: item?._id?.toString(),
    duration: formatDuration(durationInSeconds), // Use a separate function for formatting
  };
};

export const fetchIngestJobs = createAsyncThunk(
  `${INGEST_JOBS_FEATURE_KEY}/fetchAll`,
  async () => {
    try {
      let { data } = await ingestJobsApi.fetchAll();
      return (data || []).map(mapApiToStore);
    } catch (error) {
      console.log("Error fetching injest jobs data!");
    }
  },
);

export const initialState = ingestJobsAdapter.getInitialState({
  loadingStatus: requestStatus.initial,
  error: undefined,
});

export const ingestJobsSlice = createSlice({
  name: INGEST_JOBS_FEATURE_KEY,
  initialState: initialState,
  reducers: {
    add: ingestJobsAdapter.addOne,
    remove: ingestJobsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIngestJobs.pending, (state) => {
        state.loadingStatus = requestStatus.pending;
      })
      .addCase(fetchIngestJobs.fulfilled, (state, { payload }) => {
        ingestJobsAdapter.setAll(state, payload);
        state.loadingStatus = requestStatus.fulfilled;
      })
      .addCase(fetchIngestJobs.rejected, (state, action) => {
        state.loadingStatus = requestStatus.error;
        state.error = action?.error?.message || 'Error fetching ingest jobs data!';
      })
  },
});

export const ingestJobsReducer = ingestJobsSlice.reducer;

const { selectAll } = ingestJobsAdapter.getSelectors();
export const getIngestJobsState = (rootState) => rootState[INGEST_JOBS_FEATURE_KEY];
export const selectAllIngestJobs = createSelector(getIngestJobsState, selectAll);
export const selectIngestJobsLoadingStatus = createSelector(
  getIngestJobsState,
  (state) => state.loadingStatus,
);

export const selectIngestJobsFiltersOptions = createSelector(getIngestJobsState, (state) => {
  let fileTypesSet = new Set([]);
  let resourcesSet = new Set([]);
  let statusesSet = new Set([]);

  selectAll(state).forEach(item => {
    if (item?.fileType) {
      fileTypesSet.add(item?.fileType);
    }

    if (item?.resource) {
      resourcesSet.add(item?.resource || '');
    }

    if (item?.status) {
      statusesSet.add(item?.status || '');
    }
  })

  return {
    fileTypes: Array.from(fileTypesSet).sort(), // convert to array and sort alphabetically
    resources: Array.from(resourcesSet).sort(),
    statuses: Array.from(statusesSet).sort(),
  }
});
