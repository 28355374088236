export const presentationReducerActions = {
  SET_PRESENTATION_LOADING: 'SET_PRESENTATION_LOADING',
  SET_PRESENTATION_BUILD_STEP: 'SET_PRESENTATION_BUILD_STEP',
};

export default (state, action) => {
  switch (action.type) {
    case presentationReducerActions.SET_PRESENTATION_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case presentationReducerActions.SET_PRESENTATION_BUILD_STEP:
      return {
        ...state,
        buildProcessStep: action.payload.step,
      };

    default:
      return state;
  }
};
