import React from 'react';
import styled from 'styled-components';
import { noAccessMessageTypes } from '../../config/consts';

const StyledNoAccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const getMessage = (type) => {
  switch (type) {
    case noAccessMessageTypes.noContentRepositories:
      return (
        <>
          <h3>You do not have any content repositories assigned to you.</h3>
          <h3>Please contact the administrator.</h3>
        </>
      );
    default:
      return (
        <>
          <h3>Sorry, You do not have access to this page.</h3>
          <h3>Please contact the administrator.</h3>
        </>
      );
  }
};

const NoAccessMessage = ({ type }) => {
  return <StyledNoAccessMessage>{getMessage(type)}</StyledNoAccessMessage>;
};

export default NoAccessMessage;
