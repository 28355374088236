import React from 'react';
import styled from 'styled-components';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';

const StyledSlideThumbnailOverlay = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.slideSortThumbnail.backgroundColor};
  opacity: ${({ theme }) => theme.slideDividerThumbnail.draggingOpacity};

  .thumbnail-container {
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  .thumbnail {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .footer {
    width: 100%;
    padding: 0 8px 5px 8px;
  }

  .title {
    cursor: pointer;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${({ theme }) => theme.slideSortThumbnail.fontSize};
    color: ${({ theme }) => theme.slideSortThumbnail.color};
    margin-bottom: 0;
  }

  &.disabled {
    img {
      cursor: not-allowed;
    }

    > div:first-child {
      transform: none;
    }
    box-shadow: none;
  }
`;

const StyledDeleteButton = styled.button`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  z-index: 1;
  background-color: ${({ theme }) =>
    theme.slideSortThumbnail.deleteButton.backgroundColor};
  svg {
    color: ${({ theme }) => theme.slideSortThumbnail.deleteButton.color};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.slideSortThumbnail.deleteButton.hoverColor};
    }
  }

  &:disabled {
    pointer-events: none;
    svg {
      color: ${({ theme }) =>
        theme.slideSortThumbnail.deleteButton.disabledColor};
    }
  }
`;

const SlideSortThumbnailOverlay =({
  item,
  disableRemove,
}) => {
  if (!item) {
    return null;
  }

  return (
    <StyledSlideThumbnailOverlay>
      <div className="thumbnail-container">
        {!item.isRequired && (
          <StyledDeleteButton type="button" disabled={disableRemove}>
            <DeleteOutlinedIcon />
          </StyledDeleteButton>
        )}

        <img
          src={item.imageUrl}
          alt={item.title}
          className="thumbnail"
        />
      </div>

      <div className="footer">
        <h5 className="title" title={item.title}>
          {item.title}
        </h5>
      </div>
    </StyledSlideThumbnailOverlay>
  );
};

export default SlideSortThumbnailOverlay;
