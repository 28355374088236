import React from 'react';
import { Grid } from '@mui/material';
import ButtonsContainer from './ButtonsContainer';
import { Button, buttonVariants, buttonWidths } from '@clatter/ui';
import { ShareOutlined as ShareIcon } from '@mui/icons-material';

const BuildStateButtons = ({
  onDownloadButtonClick,
  showDownloadButton,
  showEditButton = true,
  onShareButtonClick,
  onBuildButtonClick,
  onEditButtonClick,
}) => {
  return (
    <Grid container spacing={5}>
      <Grid item container justifyContent="flex-end">
        <ButtonsContainer>
          {showDownloadButton && (
            <Button
              testId="share-button"
              variant={buttonVariants.clear}
              icon={ShareIcon}
              onClick={() => onShareButtonClick(true)}
            />
          )}

          {showEditButton && (
            <Button
              testId="edit-button"
              width={buttonWidths.md}
              variant={buttonVariants.dark}
              onClick={onEditButtonClick}
            >
              Edit
            </Button>
          )}

          {showDownloadButton && (
            <Button
              testId="download-button"
              width={buttonWidths.md}
              variant={buttonVariants.success}
              onClick={onDownloadButtonClick}
            >
              Download
            </Button>
          )}

          {!showDownloadButton && (
            <Button
              testId="build-button"
              width={buttonWidths.md}
              variant={buttonVariants.primary}
              onClick={onBuildButtonClick}
            >
              Build
            </Button>
          )}
        </ButtonsContainer>
      </Grid>
    </Grid>
  );
};

export default BuildStateButtons;
