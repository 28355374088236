import React from 'react';
import styled from 'styled-components';
import { CSS } from '@dnd-kit/utilities';
import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';

const StyledSlideDividerThumbnail = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) =>
    theme.slideDividerThumbnail.backgroundColor};

  &.isDragging {
    opacity: ${({ theme }) => theme.slideDividerThumbnail.draggingOpacity};
    z-index: 10;
  }

  .title {
    font-size: ${({ theme }) => theme.slideDividerThumbnail.fontSize};
    margin-bottom: 0;
  }

  .thumbnail {
    width: 100%;
    object-fit: contain;
    border-radius: ${({ theme }) => theme.slideDividerThumbnail.borderRadius};
    border: ${({ theme }) => theme.slideDividerThumbnail.border};
  }
`;

const SlideDividerThumbnail = ({ id, item }) => {
  if (!item) {
    return null;
  }
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useDraggable({
    id,
    data: {
      droppable: true,
    },
  });

  const style = {
    transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
    transition,
  };

  return (
    <StyledSlideDividerThumbnail
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames({ isDragging: isDragging })}
    >
      <img className="thumbnail" src={item.imageUrl} alt={item.title} />
      <h5 className="title">{item.title}</h5>
    </StyledSlideDividerThumbnail>
  );
};

export default SlideDividerThumbnail;
