import React from 'react';
import { tertiaryColor, Tooltip } from '@clatter/ui';
import { HelpOutlineOutlined as HelpOutlinedIcon } from '@mui/icons-material';
import styled from 'styled-components';

const StyledSlidesCounter = styled.div`
  background: ${tertiaryColor};
  padding: 12px 20px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

  p {
    margin: 0;
    white-space: nowrap;
  }

  span {
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    font-size: 1.1rem;
    color: grey;
    margin: auto auto auto 10px;
  }
`;

const SelectedSlidesCounter = ({ slidesLimit, slidesCount }) => (
  <StyledSlidesCounter>
    <p>
      Slide Count: &nbsp;
      <span>
        {slidesCount} / {slidesLimit}
      </span>
    </p>
    <Tooltip
      text={`Presentations can have a maximum of ${slidesLimit} slides.`}
      left
    >
      <HelpOutlinedIcon />
    </Tooltip>
  </StyledSlidesCounter>
);

export default SelectedSlidesCounter;
