import React, { lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import {
  useAuth,
  ProtectedRoute,
  LogoutPageView,
  fetchToolsConfig,
  fetchToolInfo,
  fetchFeatureFlag,
  fetchSystemProfile,
  sharedRoutes,
  fetchCustomAttributes, Analytics,
} from '@clatter/platform';
import {
  Loader,
  AppThemeProvider,
  appGlobalStyles,
  SsoCallbackPage,
  TrackPageviewEvent,
} from '@clatter/ui';
import { useDispatch } from 'react-redux';
import routes from './routes';
import { userRolesMap } from './helpers/userRoles';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const CallbackPageView = lazy(() =>
  import('./pages/Callback/CallbackPageView'),
);
const LandingPageView = lazy(() =>
  import('./pages/LandingPage/LandingPageView'),
);
const PresentationsListView = lazy(() =>
  import('./pages/Presentations/PresentationsPageView'),
);
const SlideDetailsView = lazy(() =>
  import('./pages/DetailView/SlideDetails/SlideDetailsView'),
);
const DeckDetailsView = lazy(() =>
  import('./pages/DetailView/DeckDetails/DeckDetailsView'),
);
const PresentationDetailsView = lazy(() =>
  import('./pages/DetailView/PresentationDetails/PresentationDetailsView'),
);
const AllPresentationsReportView = lazy(() =>
  import('./pages/Reports/AllPresentationsReport/AllPresentationsReportView'),
);
const PresentationsByUserReportView = lazy(() =>
  import('./pages/Reports/PresentationsByUser/PresentationsByUserReportView'),
);
const PresentationsByUserReportGroupedView = lazy(() =>
  import('./pages/Reports/PresentationsByUserGrouped/PresentationsByUserReportGroupedView'),
);
const SlidesUsageReportView = lazy(() =>
  import('./pages/Reports/SlidesUsage/SlidesUsageReportView'),
);
const SlidesPerformanceReportView = lazy(() =>
  import('./pages/Reports/SlidePerformance/SlidePerformanceReportView'),
);
const SlidesByUserReportView = lazy(() =>
  import('./pages/Reports/SlidesByUser/SlidesByUserReportView'),
);
const SlideDetailReportView = lazy(() =>
  import('./pages/Reports/SlideDetail/SlideDetailReportView'),
);
const PresentationsCreateReportView = lazy(() =>
  import('./pages/Reports/PresentationsCreate/PresentationsCreateReportView'),
);
const PresentationsDownloadsReportView = lazy(() =>
  import('./pages/Reports/PresentationsDownloads/PresentationsDownloadsReportView'),
);
const SlidesDownloadsReportView = lazy(() =>
  import('./pages/Reports/SlidesDownloads/SlidesDownloadsReportView'),
);
const StarterDecksListView = lazy(() =>
  import('./pages/StarterDecks/StarterDecksListView'),
);
const StarterDecksReorderView = lazy(() =>
  import('./pages/StarterDecks/StarterDeckReorderView'),
);
const BuildPageView = lazy(() => import('./pages/Build/BuildEditPageView'));
const StarterDeckBuildPageView = lazy(() =>
  import('./pages/Build/StarterDeckBuildPageView'),
);
const DecksDownloadsReportView = lazy(() =>
  import('./pages/Reports/DecksDownloads/DecksDownloadsReportView'),
);
const ContentRepositoriesListView = lazy(() =>
  import('./pages/ContentRepositories/ContentRepositoriesListView'),
);
const ContentRepositoriesFormView = lazy(() =>
  import('./pages/ContentRepositories/ContentRepositoriesFormView'),
);
const IngestJobsListView = lazy(() => import('./pages/IngestJobs/IngestJobsListView'));

const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;

export function App() {
  const location = useLocation();

  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const isOnLogoutPage = !!matchPath(location.pathname, {
    path: routes.logout,
    exact: true,
  });
  const isOnCallbackPage = !!matchPath(location.pathname, {
    path: routes.callback,
    exact: true,
  });
  const isOnSsoCallbackPage = !!matchPath(location.pathname, {
    path: sharedRoutes.ssoCallback,
    exact: true,
  });

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage && !isOnCallbackPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
      dispatch(fetchCustomAttributes());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
      <Router>
        <TrackPageviewEvent />
        <Analytics />

        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path={routes.index} component={LandingPageView} />
            <Route exact path={routes.logout} component={LogoutPageView} />
            <Route exact path={sharedRoutes.ssoCallback} component={SsoCallbackPage} />
            <ProtectedRoute
              exact
              path={routes.presentations}
              component={PresentationsListView}
              accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.presentationSetup}
              component={BuildPageView}
              accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={[routes.slideDetails, routes.shareSlide]}
              component={SlideDetailsView}
              accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={[routes.deckDetails, routes.shareDeck]}
              component={DeckDetailsView}
              accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.presentationDetails}
              component={PresentationDetailsView}
              accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
            />
            <Route exact path={routes.callback} component={CallbackPageView} />
            <Route
              exact
              path={routes.reportAllPresentations}
              component={AllPresentationsReportView}
            />
            <Route
              exact
              path={routes.reportUserPresentations}
              component={PresentationsByUserReportView}
            />
            <ProtectedRoute
              exact
              path={routes.reportUserPresentationsSpike}
              component={PresentationsByUserReportGroupedView}
              accessRoles={[userRolesMap.pgUser, userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.starterDecks}
              component={StarterDecksListView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.starterDeckSetup}
              component={StarterDeckBuildPageView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.starterDeckReorder}
              component={StarterDecksReorderView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportPresentationsCreate}
              component={PresentationsCreateReportView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportPresentationsDownloads}
              component={PresentationsDownloadsReportView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportSlidesDownloads}
              component={SlidesDownloadsReportView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <ProtectedRoute
              exact
              path={routes.reportDecksDownloads}
              component={DecksDownloadsReportView}
              accessRoles={[userRolesMap.pgSystemAdmin]}
            />
            <Route
              exact
              path={routes.reportSlidesUsage}
              component={SlidesUsageReportView}
            />
            <Route
              exact
              path={routes.reportSlideDownloadCounts}
              component={SlidesPerformanceReportView}
            />
            <Route
              exact
              path={routes.reportSlidesByUser}
              component={SlidesByUserReportView}
            />
            <Route
              exact
              path={routes.reportSlideDetail}
              component={SlideDetailReportView}
            />
            <ProtectedRoute
              exact
              path={routes.ingestJobs}
              component={IngestJobsListView}
              accessRoles={userRolesMap.pgSystemAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.contentRepositories}
              component={ContentRepositoriesListView}
              accessRoles={userRolesMap.pgSystemAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.addContentRepository}
              component={ContentRepositoriesFormView}
              accessRoles={userRolesMap.pgSystemAdmin}
            />
            <ProtectedRoute
              exact
              path={routes.editContentRepository}
              component={ContentRepositoriesFormView}
              accessRoles={userRolesMap.pgSystemAdmin}
            />
          </Switch>
        </Suspense>
      </Router>
    </AppThemeProvider>
  );
}
export default App;
