import React from 'react'
import styled from 'styled-components';

const StyledButtonsContainer = styled.div`
  display: flex;

  > * {
    margin-right: 16px;
  }
`;

const ButtonsContainer = ({ children }) => (
  <StyledButtonsContainer className="buttons-container">
    {children}
  </StyledButtonsContainer>
);


export default ButtonsContainer
