import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Typography, TextControl } from '@clatter/ui';
import {
  ModeEditOutlined as ModeEditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const InlinedEditWrapper = styled.div`
  display: flex;
  align-items: baseline;

  input {
    padding: 5px;
    height: 24px;
    margin-right: 5px;
  }
`;

const StyledImageContainer = styled.div`
  border: ${({ showBorder }) => (showBorder ? '1px solid #ccc' : 'none')};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const StyledLogoImage = styled.img`
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'initial')};
  border: 1px solid #ccc;
  padding: 0.2rem;
  height: auto;
  width: auto;
`;

const StyledLogoActions = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .actions {
    display: flex;
  }
`;

const Actions = ({ title, handleDelete, handleEditButtonClick }) =>
  (title || handleDelete || handleEditButtonClick) && (
    <StyledLogoActions>
      {title && (
        <Typography component="span" variant="body2">
          {title}
        </Typography>
      )}

      {(handleEditButtonClick || handleDelete) && (
        <div className="actions">
          {handleEditButtonClick && (
            <IconButton onClick={handleEditButtonClick}>
              <ModeEditOutlinedIcon />
            </IconButton>
          )}

          {handleDelete && (
            <IconButton onClick={handleDelete}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
        </div>
      )}
    </StyledLogoActions>
  );

const InlineEdit = ({ defaultValue, onTitleChange, onCancel }) => {
  const [newTitle, setNewTitle] = useState(defaultValue);

  return (
    <InlinedEditWrapper>
      <TextControl
        defaultValue={newTitle}
        onChange={({ currentTarget }) => setNewTitle(currentTarget.value)}
      />
      <IconButton onClick={() => onTitleChange(newTitle)}>
        <DoneIcon />
      </IconButton>
      <IconButton onClick={onCancel}>
        <CloseIcon />
      </IconButton>
    </InlinedEditWrapper>
  );
};

const ImagePreview = ({
  imageData,
  handleDelete,
  handleTitleChange,
  handleEditClick,
  handleClick,
  showBorder = true,
  justifyContent = 'space-between',
}) => {
  const [showInlinedEdit, setShowInlineEdit] = useState(false);
  const { title, url } = imageData;
  const onTitleChange = (newTitle) => {
    handleTitleChange(newTitle);
    setShowInlineEdit(false);
  };

  const onEditButtonClick = (props) => {
    if (!!handleTitleChange) {
      return setShowInlineEdit(true);
    }

    if (!!handleEditClick) {
      return handleEditClick(props);
    }

    return undefined;
  };

  return (
    <StyledImageContainer
      justifyContent={justifyContent}
      showBorder={showBorder}
      className="image-preview"
    >
      <StyledLogoImage
        showPointer={!!handleClick}
        src={url}
        alt="image"
        onClick={() => handleClick && handleClick(imageData)}
      />
      {showInlinedEdit ? (
        <InlineEdit
          defaultValue={title}
          onTitleChange={onTitleChange}
          onCancel={() => setShowInlineEdit(false)}
        />
      ) : (
        <Actions
          title={title}
          handleDelete={handleDelete}
          handleEditButtonClick={
            !!handleTitleChange || !!handleEditClick
              ? () => onEditButtonClick({ url, title })
              : null
          }
        />
      )}
    </StyledImageContainer>
  );
};

export default ImagePreview;
