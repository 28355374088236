import React from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';


const useContentFilter = () => {
  const contentFilters = useSelector(
    platformSelectors.contentFilters.selectAllContentFilters,
  );

  const loadingStatus = useSelector(
    ({ platform: { contentFilters } }) => contentFilters.loadingStatus,
  );

  return { contentFilters, loadingStatus };
};

export default useContentFilter;
