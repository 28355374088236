import { getAxiosWithAuthorization } from '@clatter/platform';

const customerApi = {
  getLogos: ({ customerId }) =>
    getAxiosWithAuthorization({
      url: `/customer/${customerId}/logo`,
    }),
  uploadCustomerLogo: ({ customerId, data }) =>
    getAxiosWithAuthorization({
      url: `/customer/${customerId}/logo`,
      method: 'post',
      data: data,
    }),
  deleteCustomerProfileLogo: ({ customerId, logoId }) =>
    getAxiosWithAuthorization({
      url: `/customer/${customerId}/logo/${logoId}`,
      method: 'delete',
    }),
  updateCustomerProfileLogoTitle: ({ customerId, logoId, newTitle }) =>
    getAxiosWithAuthorization({
      url: `/customer/${customerId}/logo/${logoId}`,
      method: 'patch',
      data: {
        title: newTitle,
      },
    }),
};

export default customerApi;
