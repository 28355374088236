import React from 'react';
import styled from 'styled-components';
import { Card } from '@clatter/ui';
import SlideSortThumbnail from './SlideSortThumbnail';
import SlideListItem from './SlideListItem';
import { DragOverlay } from '@dnd-kit/core';
import SlideSortThumbnailOverlay from './SlideSortThumbnailOverlay';
import SlideListItemOverlay from './SlideListItemOverlay';

const StyledThumbnailView = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`;

const StyledListView = styled.div`
  > div {
    width: 100%;
    padding: 20px 30px;
    user-select: none;

    .content {
      columns: 250px 3;
    }
  }
`;

const isNew = (slideId, newSlidesIds) => {
  return newSlidesIds?.some((id) => id === slideId);
};

export const getRestrictedState = ({
  slideId,
  groupId,
  contentSlidesGroups,
}) => {
  if (groupId) {
    //check if slide is last in the group
    const slideIndex = contentSlidesGroups[groupId].findIndex(
      (id) => id === slideId,
    );
    if (slideIndex < contentSlidesGroups[groupId].length - 1) return true;
  }
  return false;
};

const ThumbnailView = ({
  slides,
  newSlidesIds,
  isSlideActive,
  overId,
  activeId,
  onPreview,
  onSelect,
  onRemove,
  zoomValue,
  contentSlidesGroups,
}) => (
  <StyledThumbnailView>
    {slides.map((slide, index) => {
      return (
        <SlideSortThumbnail
          index={index}
          key={slide.hashedId}
          id={slide.hashedId}
          item={slide}
          isActive={isSlideActive(slide.hashedId)}
          isNew={isNew(slide.id, newSlidesIds)}
          slideOver={overId === slide.hashedId && overId !== activeId}
          disableRemove={slides.length <= 1}
          disabled={slide.isDisabled}
          onSelect={onSelect}
          onRemove={onRemove}
          onPreview={() => onPreview(index)}
          zoomValue={zoomValue}
          contentSlidesGroups={contentSlidesGroups ?? {}}
        />
      );
    })}
  </StyledThumbnailView>
);

const ListView = ({
  slides,
  newSlidesIds,
  isSlideActive,
  overId,
  activeId,
  onPreview,
  onRemove,
  onSelect,
  contentSlidesGroups,
}) => (
  <StyledListView>
    <Card>
      <div className="content">
        {slides.map((slide, index) => {
          return (
            <SlideListItem
              index={index}
              key={slide.hashedId}
              id={slide.hashedId}
              item={slide}
              isActive={isSlideActive(slide.hashedId)}
              isNew={isNew(slide.id, newSlidesIds)}
              slideOver={overId === slide.hashedId && overId !== activeId}
              disableRemove={slides.length <= 1}
              disabled={slide.isDisabled}
              onSelect={onSelect}
              onRemove={onRemove}
              onPreview={() => onPreview(index)}
              counter={index + 1}
              contentSlidesGroups={contentSlidesGroups ?? {}}
            />
          );
        })}
      </div>
    </Card>
  </StyledListView>
);

const SortTabSlidesHierarchy = ({ activeView, ...props }) => {
  return (
    <>
      {activeView === 'thumbnail' ? (
        <ThumbnailView {...props} />
      ) : (
        <ListView {...props} />
      )}
      <DragOverlay>
        {activeView === 'thumbnail' ? (
          <SlideSortThumbnailOverlay
            {...props}
            item={props.slides[props.activeSlideIndex]}
          />
        ) : (
          <SlideListItemOverlay
            {...props}
            item={props.slides[props.activeSlideIndex]}
            counter={props.activeSlideIndex + 1}
          />
        )}
      </DragOverlay>
    </>
  );
};

export default SortTabSlidesHierarchy;
